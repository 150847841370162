<template>
  <v-container>
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>mdi-account-group</v-icon>
        {{ cliente.nome_fantasia }}
        <v-spacer></v-spacer>
        <v-btn @click="gerarpdf" icon large>
          <v-icon>mdi-file-pdf</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-tabs v-model="tab">
        <v-tab>Dados</v-tab>
        <v-tab>Equipe</v-tab>
        <v-tab>Atividades</v-tab>
        <v-tab>Configurações</v-tab>
        <v-tab>Senhas</v-tab>
      </v-tabs>
      <!-- <v-divider></v-divider> -->
      <v-tabs-items v-model="tab">
        <!-- Dados -->
        <v-tab-item>
          <v-card-text class="pa-10">
            <v-form ref="form" v-model="validForm" lazy-validation>
              <v-row>
                <v-col cols="12" md="3">
                  <!-- <h1 class="text-h6 font-weight-bold mb-4">Logo</h1> -->
                  <v-card
                    outlined
                    class="fill-height d-flex align-center justify-center"
                    style="position: relative"
                  >
                    <v-card flat class="pa-4">
                      <v-img
                        v-if="cliente.logo"
                        :src="cliente.logo"
                        contain
                      ></v-img>
                      <v-icon size="200" v-else>mdi-account</v-icon>
                    </v-card>

                    <v-btn
                      fab
                      dark
                      x-small
                      absolute
                      bottom
                      right
                      @click="onButtonClick"
                    >
                      <input
                        accept="image/*"
                        type="file"
                        class="d-none"
                        ref="uploader"
                        @change="onFileChanged"
                      />
                      <v-icon>mdi-camera</v-icon>
                    </v-btn>
                  </v-card>
                </v-col>

                <!--dados -->
                <v-col cols="12" md="9" class="px-3">
                  <h1 class="text-h6 font-weight-bold mb-3">Dados</h1>
                  <v-row>
                    <!--Razao Social -->
                    <v-col cols="12" md="9">
                      <v-text-field
                        label="Razão Social"
                        v-model="cliente.razao_social"
                        dense
                        :rules="formRules"
                        required
                      >
                      </v-text-field>
                    </v-col>

                    <!-- Status -->
                    <v-col cols="12" md="3">
                      <v-text-field dense readonly value="Status:">
                        <template v-slot:append>
                          <v-switch
                            v-model="cliente.status"
                            :false-value="0"
                            :true-value="1"
                            class="mt-0 pt-0"
                            hide-details
                            :rules="formRules"
                            required
                          >
                          </v-switch>
                        </template>
                      </v-text-field>
                    </v-col>

                    <!--Nome Fantasia -->
                    <v-col cols="12" md="12">
                      <v-text-field
                        label="Nome de Fantasia"
                        v-model="cliente.nome_fantasia"
                        dense
                        :rules="formRules"
                        required
                      >
                      </v-text-field>
                    </v-col>

                    <!--CNPJ -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-mask="`##.###.###/####-##`"
                        label="CNPJ"
                        v-model="cliente.cnpj"
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <!--Inscricao Estadual -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Inscricao Estadual"
                        v-mask="`#########.##-##`"
                        v-model="cliente.inscricao_estadual"
                        dense
                      ></v-text-field>
                    </v-col>

                    <!--CPF -->
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-mask="`###.###.###-##`"
                        label="CPF"
                        v-model="cliente.cpf"
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <!--RG -->
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-mask="`#######-#`"
                        label="RG"
                        v-model="cliente.rg"
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <!--CI -->
                    <v-col cols="12" md="2">
                      <v-text-field label="CI" v-model="cliente.ci" dense>
                      </v-text-field>
                    </v-col>

                    <!--Ramo de Negocio -->
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="Ramo de Negócio"
                        v-model="cliente.ramo_negocio"
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <!--E-mail  -->
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="cliente.email"
                        label="E-mail"
                        dense
                      ></v-text-field>
                    </v-col>

                    <!-- telefone -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="cliente.telefone"
                        v-mask="`(##) ####-####`"
                        label="Telefone"
                        dense
                      ></v-text-field>
                    </v-col>

                    <!-- celular -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="cliente.celular"
                        v-mask="`(##) #.####-####`"
                        label="Celular"
                        dense
                        required
                        :rules="formRules"
                      ></v-text-field>
                    </v-col>

                    <!-- whatsapp -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="cliente.whatsapp"
                        v-mask="`(##) #.####-####`"
                        label="Whatsapp"
                        dense
                        required
                        :rules="formRules"
                      ></v-text-field>
                    </v-col>

                    <!--Telegram -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="cliente.telegram"
                        v-mask="`(##) #.####-####`"
                        label="Telegram"
                        dense
                        required
                        :rules="formRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <!--Endereco -->
                <v-col cols="12" class="pa-3">
                  <h1 class="text-h6 font-weight-bold mb-3">Endereço</h1>
                  <v-row>
                    <!-- CEP -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="cep"
                        v-mask="`#####-###`"
                        label="CEP"
                        dense
                        append-icon="mdi-magnify"
                        @keyup.enter="fetchCep"
                        @click:append="fetchCep"
                        :loading="loadingCep"
                      ></v-text-field>
                    </v-col>

                    <!--endereco -->
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="Endereço"
                        v-model="cliente.endereco"
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <!--('numero')- -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Número"
                        v-model="cliente.numero"
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <!--('('bairro')-')- -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Bairro"
                        v-model="cliente.bairro"
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <!--('('complemento')-')- -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Complemento"
                        v-model="cliente.complemento"
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <!-- Cidade -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Cidade"
                        v-model="cliente.cidade"
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <!-- UF -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="cliente.uf"
                        label="UF"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <!--Contatos -->
                <v-col cols="12" class="pa-3">
                  <h1 class="text-h6 font-weight-bold mb-3">Mídia</h1>
                  <v-row>
                    <!--Site -->
                    <v-col cols="12" md="12">
                      <v-text-field label="Site" v-model="cliente.site" dense>
                      </v-text-field>
                    </v-col>

                    <!-- Instagram -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Instagram"
                        v-model="cliente.instagram"
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <!-- Facebook -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Facebook"
                        v-model="cliente.facebook"
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <!-- Linkedin -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Linkedin"
                        v-model="cliente.linkedin"
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <!-- Youtube -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Youtube"
                        v-model="cliente.youtube"
                        dense
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <!--Descrição dos serviços contratados -->
                <v-col cols="12" class="pa-3">
                  <h1 class="text-h6 font-weight-bold">
                    Descrição dos serviços contratados
                  </h1>

                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        v-model="cliente.descricao_servicos"
                        placeholder="Insira aqui a descrição dos serviços..."
                        auto-grow
                        :rows="4"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-col>

                <!--Prazo do contrato -->
                <v-col cols="12" class="pa-3">
                  <h1 class="text-h6 font-weight-bold mb-3">
                    Prazo do contrato
                  </h1>

                  <v-row>
                    <v-col cols="12" md="6">
                      <DataField
                        label="Inicio em"
                        :data_sync.sync="cliente.inicio_contrato"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <DataField
                        label="Término em"
                        :data_sync.sync="cliente.termino_contrato"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="pa-4">
            <v-btn to="/gestor/clientes" exact> Voltar </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              @click="updateCliente"
              :disabled="!validForm"
              color="secondary"
              class="white--text"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-tab-item>

        <!-- Equipe -->
        <v-tab-item>
          <Equipe :cliente_id="cliente_id" :key="tab" />
        </v-tab-item>
        <v-tab-item>
          <ListaAtividades :cliente_id="cliente_id" :clienteComponent="true" :key="tab" />
        </v-tab-item>

        <!-- Configuracoes -->
        <v-tab-item class="pt-8">
          <Configuracoes :cliente_id="cliente_id" :key="tab" />
        </v-tab-item>

        <!-- Senhas -->
        <v-tab-item class="pt-8">
          <Senhas :cliente_id="cliente_id" :key="tab" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <PdfDialogComp
      v-if="pdfDialog"
      :pdfDialog.sync="pdfDialog"
      :pdfData="pdfData"
      :title="cliente.nome_fantasia"
    />
  </v-container>
</template>

<script>
import { fetchCliente, putCliente, uploadLogo } from "@/api/gestor/clientes.js";
import { inputRequired } from "@/helpers/utils.js";
import { buscaCep } from "@/api/geral/buscaCep.js";

const pdfGenerator = () =>
  import(/* webpackChunkName: "relatorio" */ "./pdf/clientesPdf.js");

export default {
  name: "Cliente",

  props: ["clienteId"],

  components: {
    PdfDialogComp: () => import("../../../../components/PdfDialogComp.vue"),
    DataField: () => import("../../../../components/fields/DataField.vue"),
    Equipe: () => import("./equipes/Equipe.vue"),
    Configuracoes: () => import("./components/Configuracoes.vue"),
    ListaAtividades: () => import("../../atividades/ListaAtividades.vue"),
    Senhas: () => import("./components/Senhas.vue"),
  },

  data() {
    return {
      tab: 0,
      cliente: {
        logo: "",
        nome_fantasia: "",
        razao_social: "",
        cpf: "",
        rg: "",
        cnpj: "",
        status: 1,
        inscricao_estadual: "",
        ramo_negocio: "",
        site: "",
        instagram: "",
        facebook: "",
        linkedin: "",
        youtube: "",
        endereco: "",
        numero: "",
        bairro: "",
        complemento: "",
        cep: "",
        uf: "",
        cidade: "",
        celular: "",
        whatsapp: "",
        telegram: "",
        email: "",
      },
      loading: false,
      adicionar: null,
      search: "",
      item: {},
      logo: "",
      validForm: true,
      formRules: [inputRequired],
      cep: "",
      loadingCep: false,
      pdfDialog: false,
      pdfData: "",
    };
  },

  computed: {
    cliente_id() {
      return this.$route.params.clienteId;
    },
  },

  methods: {
    fetchCep() {
      if (this.cep && this.cep.length === 9) {
        this.cliente.cep = "";
        this.cliente.cidade = "";
        this.cliente.bairro = "";
        this.cliente.uf = "";
        this.cliente.endereco = "";

        this.loadingCep = true;
        buscaCep(this.cep)
          .then((response) => {
            this.cliente.cep = response.cep;
            this.cliente.cidade = response.city;
            this.cliente.bairro = response.neighborhood;
            this.cliente.uf = response.state;
            this.cliente.endereco = response.street;
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.$toast.error("CEP não encontrado!");
            }
          })
          .finally(() => {
            this.loadingCep = false;
          });
      }
    },

    getCliente() {
      this.loading = true;
      fetchCliente(this.cliente_id)
        .then((response) => {
          this.cliente = response;
          this.cep = this.cliente.cep;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    updateCliente() {
      this.loading = true;
      putCliente(this.cliente_id, this.cliente)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Atualizado com sucesso");
            this.getCliente();
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onButtonClick() {
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      const file = e.target.files[0];
      const vue = this;
      const reader = new FileReader();
      let logo64 = null;
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event) => {
        logo64 = event.target.result;
        vue.upLogo(file, logo64);
      };
    },
    upLogo(logo, logo64) {
      const fd = new FormData();
      fd.append("logo", logo, logo.name);
      fd.append("logo64", logo64);

      uploadLogo(this.cliente.id, fd)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Logo atualizada com sucesso!");
            this.getCliente();
          }
        })
        .catch(() => {});
    },

    async gerarpdf() {
      try {
        this.loading = true;
        this.$Progress.start();

        await pdfGenerator()
          .then((module) => {
            let generate = module.generatePdf;
            generate(this.cliente).then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            });
          })
          .finally(() => {
            this.loading = false;
            this.$Progress.finish();
          });
      } catch (error) {
        this.loading = false;
        this.$Progress.fail();
      }
    },
  },

  mounted() {
    this.getCliente();
  },
};
</script>

//
<style lang="scss" scoped>
// .v-card--reveal {
//   align-items: flex-start;
//   bottom: 0;
//   justify-content: center;
//   position: absolute;
//   width: 100%;
// }

// .btn-toggle-mobile {
//   display: flex;
//   flex-direction: column;
//   width: 100% !important;
//   & > .v-btn.v-btn:not(:first-child) {
//     border-left-width: thin;
//   }
//   & > .v-btn.v-btn:first-child {
//     border-top-left-radius: 4px;
//     border-top-right-radius: 4px;
//     border-bottom-left-radius: 0px;
//     border-bottom-right-radius: 0px;
//   }
//   & > .v-btn.v-btn:last-child {
//     border-top-left-radius: 0px;
//     border-top-right-radius: 0px;
//     border-bottom-left-radius: 4px;
//     border-bottom-right-radius: 4px;
//   }
// }
</style>
